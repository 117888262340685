import { TradingDocument } from "api/trading-documents/models";
import { InfoLabel } from "components/common/infoLabel";
import { StateLabel } from "components/common/stateLabel";
import { RightPanelSection } from "components/utils/drawer";
import { dateUtils, pluralize } from "utilities";
import styles from "../RightPanel.module.css";
import { getOrderRedirectUrl } from "utilities/getOrderRedirectUrl";
import { MoreDataCounter } from "components/common/moreDataCounter";
import { useState } from "react";
import cx from "classnames";
import { AssignedUser } from "components/common/assignUser/AssignUser";
import { LinkToPage } from "components/utils/linkToPage";
import { usePatchTradingDocumentMutation } from "api/trading-documents/hooks";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Button } from "components/miloDesignSystem/atoms/button";
import { DatePicker } from "components/miloDesignSystem/molecules/datepicker/DatePicker";
import { BuyerSection } from "./BuyerSection";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Select } from "components/miloDesignSystem/molecules/select";
import { useSelector } from "hooks";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { assertIsDefined } from "utilities/assertIsDefined";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiCopyAll } from "components/miloDesignSystem/atoms/icons/MdiCopyAll";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";

interface Props {
  tradingDocument: TradingDocument;
}

export const GeneralInfoSection = ({ tradingDocument }: Props) => {
  const [showAllOrders, setShowAllOrders] = useState(false);
  const changeAssignedUserMutation = usePatchTradingDocumentMutation();
  const changeInvoiceDeliveryDateMutation = usePatchTradingDocumentMutation();
  const isReceipt = tradingDocument.type === "RECEIPT";
  const isPurchase = tradingDocument.invoiceType === "PURCHASE";
  const isPaymentDeadlineEditable =
    tradingDocument.invoiceType === "SALES" ||
    tradingDocument.invoiceType === "CORRECTION" ||
    tradingDocument.invoiceType === "PURCHASE";
  const sellers = useSelector(store => store.partials.businessEntities);
  const sellersOptions = sellers
    .filter(seller => seller.kind === "EXTERNAL")
    .map(seller => ({
      text: seller.companyName,
      type: MenuItemType.TEXT,
      value: seller.id,
    }));

  return (
    <RightPanelSection>
      <div className="d-flex align-items-start justify-content-between">
        <div>
          <InfoLabel title="przypisano do">
            <AssignedUser
              value={tradingDocument?.assignedTo}
              onChange={value =>
                changeAssignedUserMutation.mutate({
                  assignedTo: value,
                  id: tradingDocument.id,
                })
              }
            />
          </InfoLabel>
          <InfoLabel title="XML pobrano">
            {tradingDocument.xmlOptimaDownloadAt && tradingDocument.xmlOptimaDownloadBy ? (
              <div className="d-flex align-items-center gap-2">
                <Avatar size="sm" user={tradingDocument.xmlOptimaDownloadBy} />
                <Typography fontSize="14" fontWeight="700">
                  {tradingDocument.xmlOptimaDownloadBy.firstName}{" "}
                  {tradingDocument.xmlOptimaDownloadBy.lastName}
                </Typography>
                <Typography color="neutralBlack48" fontSize="12" fontWeight="700">
                  {tradingDocument.xmlOptimaDownloadAt
                    ? dateUtils.formatDateToDisplay(tradingDocument.xmlOptimaDownloadAt)
                    : EMPTY_VALUE}
                </Typography>
              </div>
            ) : (
              <EmptyValue fontSize="14" />
            )}
          </InfoLabel>
          <InfoLabel title="data wystawienia">
            {isReceipt ? (
              <Typography fontSize="14" fontWeight="500">
                {tradingDocument.invoiceIssueDate
                  ? dateUtils.formatDateToDisplay(tradingDocument.invoiceIssueDate)
                  : EMPTY_VALUE}
              </Typography>
            ) : (
              <DatePicker.Async
                isNullable
                mutationHook={usePatchTradingDocumentMutation}
                transformQueryData={date => ({
                  id: tradingDocument.id,
                  invoiceIssueDate: date ? dateUtils.formatDateToIso(date) : null,
                  pitTaxObligationDate: date ? dateUtils.formatDateToIso(date) : null,
                  vatTaxObligationDate: date ? dateUtils.formatDateToIso(date) : null,
                })}
                value={tradingDocument.invoiceIssueDate}
              />
            )}
          </InfoLabel>
          <InfoLabel title="data sprzedaży">
            {isReceipt ? (
              <Typography fontSize="14" fontWeight="700">
                {tradingDocument.invoiceDeliveryDate
                  ? dateUtils.formatDateToDisplay(tradingDocument.invoiceDeliveryDate)
                  : EMPTY_VALUE}
              </Typography>
            ) : (
              <DatePicker.Async
                isNullable
                mutationHook={usePatchTradingDocumentMutation}
                transformQueryData={date => ({
                  id: tradingDocument.id,
                  invoiceDeliveryDate: date ? dateUtils.formatDateToIso(date) : null,
                  pitTaxObligationDate: date ? dateUtils.formatDateToIso(date) : null,
                  vatTaxObligationDate: date ? dateUtils.formatDateToIso(date) : null,
                })}
                value={tradingDocument.invoiceDeliveryDate}
              />
            )}
          </InfoLabel>
          <InfoLabel title="data utworzenia">
            <Typography fontSize="14" fontWeight="700">
              {dateUtils.formatDateToDisplay(tradingDocument.createdAt)}
            </Typography>
          </InfoLabel>
          <InfoLabel title="data płatności">
            <div className="d-flex align-items-center gap-2">
              <Typography fontSize="14" fontWeight="700">
                {tradingDocument.invoicePaymentDate
                  ? dateUtils.formatDateToDisplay(tradingDocument.invoicePaymentDate)
                  : EMPTY_VALUE}
              </Typography>
              <Tooltip title="Ustaw datę sprzedaży równą dacie płatności">
                <IconButton
                  icon={MdiCopyAll}
                  isLoading={changeInvoiceDeliveryDateMutation.isLoading}
                  onClick={() =>
                    changeInvoiceDeliveryDateMutation.mutate({
                      id: tradingDocument.id,
                      invoiceDeliveryDate: tradingDocument.invoicePaymentDate,
                    })
                  }
                  variant="transparent"
                />
              </Tooltip>
            </div>
          </InfoLabel>
          <InfoLabel title="sprzedawca">
            {isPurchase ? (
              <Select.Async
                items={sellersOptions}
                mutationHook={usePatchTradingDocumentMutation}
                selected={tradingDocument.seller?.id ?? null}
                transformQueryData={sellerId => {
                  const seller = sellers.find(seller => seller.id === sellerId);
                  assertIsDefined(seller);
                  return {
                    id: tradingDocument.id,
                    seller,
                    sellerCity: seller.city,
                    sellerCompanyName: seller.companyName,
                    sellerEmail: seller.email,
                    sellerFirstName: seller.firstName,
                    sellerLastName: seller.lastName,
                    sellerPhone: seller.phone,
                    sellerPostCode: seller.postCode,
                    sellerStreet: seller.address,
                    sellerTaxId: seller.taxId,
                  };
                }}
              />
            ) : (
              <StateLabel kind="orangeDark">
                <Typography fontSize="10" fontWeight="600">
                  {Boolean(tradingDocument.sellerCompanyName)
                    ? `${tradingDocument.sellerCompanyName}`
                    : `${tradingDocument.sellerFirstName} ${tradingDocument.sellerLastName}`}
                </Typography>
              </StateLabel>
            )}
            <div className="d-flex align-items-center gap-1">
              <Typography color="grey400" fontSize="14" fontWeight="600">
                NIP:
              </Typography>
              <Typography fontSize="14" fontWeight="600">
                {Boolean(tradingDocument.seller?.taxId)
                  ? `${tradingDocument.seller?.taxId}`
                  : EMPTY_VALUE}
              </Typography>
            </div>
          </InfoLabel>

          <BuyerSection tradingDocument={tradingDocument} />

          <InfoLabel title="termin płatności">
            <div className="d-flex gap-2">
              {isPaymentDeadlineEditable ? (
                <DatePicker.Async
                  isNullable
                  mutationHook={usePatchTradingDocumentMutation}
                  transformQueryData={date => ({
                    id: tradingDocument.id,
                    paymentDeadline: date ? dateUtils.formatDateToIso(date) : null,
                  })}
                  value={tradingDocument.paymentDeadline}
                />
              ) : (
                <Typography fontSize="16" fontWeight="700">
                  {tradingDocument.paymentDeadline
                    ? dateUtils.formatDateToDisplay(tradingDocument.paymentDeadline)
                    : EMPTY_VALUE}
                </Typography>
              )}
              {tradingDocument.basePaymentDeadlineDelta &&
                tradingDocument.expectedPaymentForm !== "CASH" && (
                  <div className="d-flex align-items-center">
                    <StateLabel kind="cyan">
                      <Typography color="cyan500" fontSize="10" fontWeight="600">
                        {tradingDocument.basePaymentDeadlineDelta}{" "}
                        {pluralize.pl(tradingDocument.basePaymentDeadlineDelta, {
                          singular: "dzień",
                          plural: "dni",
                          other: "dni",
                        })}
                      </Typography>
                    </StateLabel>
                  </div>
                )}
            </div>
          </InfoLabel>
          <InfoLabel title="zamówienia">
            {Boolean(tradingDocument.orders?.length) &&
              !showAllOrders &&
              tradingDocument.orders.slice(0, 2).map((order, index) => {
                if (order !== null) {
                  return (
                    <LinkToPage
                      content={
                        <>
                          <div className="fs-14 fw-500">{order.signature}</div>
                          {index + 1 !== tradingDocument.orders.length &&
                            tradingDocument.orders[index + 1] !== null &&
                            index < 1 && <div>,</div>}
                        </>
                      }
                      key={order.id}
                      url={getOrderRedirectUrl(order)}
                    />
                  );
                }
                return null;
              })}
            {Boolean(tradingDocument.orders?.length) && showAllOrders && (
              <div className={cx(styles.showAllOrders)}>
                <div className="d-flex align-items-center flex-wrap">
                  {tradingDocument.orders.map((order, index) => {
                    if (order !== null) {
                      return (
                        <LinkToPage
                          content={
                            <>
                              <div className="fs-14 fw-500">{order.signature}</div>
                              {index + 1 !== tradingDocument.orders.length &&
                                tradingDocument.orders[index + 1] !== null && <div>,&nbsp;</div>}
                            </>
                          }
                          url={getOrderRedirectUrl(order)}
                        />
                      );
                    }
                    return null;
                  })}
                </div>
                <Button
                  className="mt-2 text-uppercase"
                  onClick={() => setShowAllOrders(prev => !prev)}
                  size="small"
                  variant="gray"
                >
                  Ukryj
                </Button>
              </div>
            )}
            {tradingDocument.orders.filter(order => order).length - 2 > 0 && !showAllOrders && (
              <div onClick={() => setShowAllOrders(prev => !prev)}>
                <MoreDataCounter
                  className={styles.moreDataCounterOverride}
                  counter={tradingDocument.orders.filter(order => order).length - 2}
                />
              </div>
            )}
          </InfoLabel>
          {tradingDocument.invoiceType === "SALES" && (
            <InfoLabel title="proforma">
              {tradingDocument.proforma ? (
                <LinkToPage
                  content={
                    <Typography color="inherit" fontSize="14" fontWeight="600">
                      {tradingDocument.proforma.signature}
                    </Typography>
                  }
                  url={`/finances/proformas/list/all?panelId=${tradingDocument.proforma.id}`}
                />
              ) : (
                <EmptyValue />
              )}
            </InfoLabel>
          )}
        </div>
      </div>
    </RightPanelSection>
  );
};
