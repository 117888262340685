import { BulkSalesInvoiceConfirmPreview, TradingDocument } from "api/trading-documents/models";
import { ModuleRow } from "components/utils/moduleList/components/ModuleRow";
import styles from "../../tradingDocumentsList/TradingDocumentsList.module.css";
import cx from "classnames";
import {
  advanceInvoiceListConfig,
  proformaInvoiceListConfig,
  salesInvoiceListConfig,
} from "components/utils/moduleList/utils/moduleListConfig";
import { StateLabel } from "components/common/stateLabel";
import { dateUtils, handleDateField, pluralize } from "utilities";
import { InitialsAvatar } from "components/utils/userAvatar/InitialsAvatar";
import {
  isDateOlderThan24Hours,
  isDateOlderThan48Hours,
} from "pages/tradingDocuments/shared/utils/isDateOlderThan24Hours";
import { dateExceedsToday } from "pages/tradingDocuments/shared/utils/dateExceedsToday";
import { PaymentDeadlineDeltaLabel } from "pages/tradingDocuments/shared/components/PaymentDeadlineDeltaLabel";
import { getStatusLabelKind } from "pages/tradingDocuments/shared/utils/getStatusLabelKind";
import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { MoreDataCounter } from "components/common/moreDataCounter";
import { ConfirmCheckbox } from "pages/tradingDocuments/shared/components/confirmCheckbox/ConfirmCheckbox";
import { UUID } from "api/types";
import { HighlightedRow } from "api/other/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useStateModal } from "hooks";
import { ReplyModal } from "pages/tradingDocuments/shared/components/actionToolbar/ReplyModal";
import { countryToFlagDict } from "constants/countriesFlags";
import { tradingDocumentConstants } from "constants/tradingDocuments";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";

interface Props {
  highlightedRows: HighlightedRow[];
  index: number;
  isActive: (id: string) => boolean;
  isHighlighted: (id: string) => boolean;
  togglePanel: (id: string) => void;
  tradingDocument: TradingDocument;
  selectMutlipleItems: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: UUID,
    index: number,
    togglePanel: () => void,
  ) => void;
}

export const ListItem = ({
  highlightedRows,
  index,
  isActive,
  isHighlighted,
  togglePanel,
  tradingDocument,
  selectMutlipleItems,
}: Props) => {
  const replyModal = useStateModal<BulkSalesInvoiceConfirmPreview>();

  const advanceInvoices = tradingDocument.correspondingDocuments.filter(
    tradingDocument => tradingDocument.invoiceType === "ADVANCE",
  );

  return (
    <>
      <ModuleRow
        className={cx({
          active: isActive(tradingDocument.id),
          highlighted:
            isHighlighted(tradingDocument.id) ||
            highlightedRows.some(row => row.id === tradingDocument.id),
        })}
        gridStyle={
          tradingDocument.invoiceType === "SALES"
            ? salesInvoiceListConfig.grid
            : tradingDocument.invoiceType === "ADVANCE"
            ? advanceInvoiceListConfig.grid
            : proformaInvoiceListConfig.grid
        }
        onMouseDown={event =>
          selectMutlipleItems(event, tradingDocument.id, index, () =>
            togglePanel(tradingDocument.id),
          )
        }
      >
        <div>
          <ConfirmCheckbox tradingDocument={tradingDocument} replyModal={replyModal} />
        </div>
        <div className="d-flex align-items-center gap-1">
          <div className={cx(styles.client, "fw-700")}>
            {tradingDocument.recipientCompanyName ? (
              tradingDocument.recipientCompanyName
            ) : (
              <>
                {tradingDocument.recipientFirstName} {tradingDocument.recipientLastName}
              </>
            )}
          </div>
          {!isDateOlderThan48Hours(tradingDocument.createdAt) && (
            <StateLabel
              kind="green"
              opacity={
                tradingDocument.createdAt && isDateOlderThan24Hours(tradingDocument.createdAt)
                  ? "high"
                  : "default"
              }
            >
              <div>{tradingDocumentConstants.invoiceStatusDict.NEW}</div>
            </StateLabel>
          )}
        </div>
        <div className={styles.signature}>{tradingDocument.signature}</div>
        {tradingDocument.amountSummary.totalWithTax ? (
          <div className={cx(styles.amountToPay, "d-flex align-items-center")}>
            <FinanceAmountFormat value={tradingDocument.amountSummary.totalWithTax} />
            <div className="fs-10 text-color-grey">{tradingDocument.currency}</div>
          </div>
        ) : (
          <Typography fontSize="12" fontWeight="600">
            brak
          </Typography>
        )}
        {tradingDocument.invoiceType === "SALES" && (
          <div className="d-flex align-items-center gap-3">
            {Boolean(advanceInvoices.length) ? (
              advanceInvoices.slice(0, 2).map(document => (
                <div className="d-flex align-items-center gap-1" key={document.id}>
                  <Typography
                    color={document.status === "CONFIRMED" ? "success500" : "neutralBlack100"}
                    fontSize="12"
                    fontWeight="700"
                    noWrap
                  >
                    {document.signature}
                  </Typography>
                  {document.status === "CONFIRMED" && <MdiCheck size="12" />}
                </div>
              ))
            ) : (
              <EmptyValue fontSize="14" />
            )}
            {Boolean(advanceInvoices.length - 2 > 0) && (
              <MoreDataCounter
                className={styles.moreDataCounterOverride}
                counter={advanceInvoices.length - 2}
                style={{ width: "max-content" }}
              />
            )}
          </div>
        )}
        {tradingDocument.invoiceType === "ADVANCE" && (
          <div className="d-flex align-items-center gap-2">
            <Typography fontSize="12" fontWeight="700" noWrap>
              {Boolean(tradingDocument.correspondingDocuments.length)
                ? tradingDocument.correspondingDocuments[0].signature
                : EMPTY_VALUE}
            </Typography>
            {Boolean(tradingDocument.correspondingDocuments.length - 1 > 0) && (
              <MoreDataCounter
                className={styles.moreDataCounterOverride}
                counter={tradingDocument.correspondingDocuments.length - 1}
                style={{ width: "max-content" }}
              />
            )}
          </div>
        )}
        {Boolean(tradingDocument.countryCode.length) ? (
          <div className="d-flex align-items-center gap-1">
            <img alt="Flaga kraju" src={countryToFlagDict[tradingDocument.countryCode]} />
            <Typography fontSize="12" fontWeight="700">
              {tradingDocument.countryCode}
            </Typography>
          </div>
        ) : (
          <Typography className="d-flex justify-content-center" fontSize="12" fontWeight="700">
            --
          </Typography>
        )}
        <div className={styles.date}>{handleDateField(tradingDocument.invoiceIssueDate)}</div>
        <div className={cx(styles.date, "d-flex align-items-center")}>
          {tradingDocument.paymentDeadline ? (
            <div
              className={cx({
                "text-red-6": dateExceedsToday(tradingDocument.paymentDeadline),
              })}
            >
              {dateUtils.formatDateToDisplay(tradingDocument.paymentDeadline)}
            </div>
          ) : (
            "---"
          )}
          {tradingDocument.basePaymentDeadlineDelta && (
            <div>
              &nbsp;&nbsp;({tradingDocument.basePaymentDeadlineDelta}{" "}
              {pluralize.pl(tradingDocument.basePaymentDeadlineDelta, {
                singular: "dzień",
                plural: "dni",
                other: "dni",
              })}
              )
            </div>
          )}
        </div>
        <div className={cx(styles.signature, "d-flex align-items-center")}>
          {tradingDocument.orders.slice(0, 1).map(order => {
            return order ? order.signature : "";
          })}{" "}
          &nbsp;
          {tradingDocument.orders.filter(order => order).length - 1 > 0 ? (
            <MoreDataCounter
              className={styles.moreDataCounterOverride}
              counter={tradingDocument.orders.filter(order => order).length - 1}
              style={{ width: "max-content" }}
            />
          ) : null}
        </div>
        {(tradingDocument.invoiceType === "SALES" || tradingDocument.invoiceType === "ADVANCE") && (
          <div className="d-flex align-items-center gap-2">
            {tradingDocument.isDelivered ? (
              <Tag label="dostarczono" startIcon={MdiCheck} variant="success" />
            ) : (
              <Tag label="Nie dostarczono" type="outlined" variant="quaternary" />
            )}
          </div>
        )}
        <div>
          <StateLabel
            kind={getStatusLabelKind(
              tradingDocument.paymentStatus,
              tradingDocument.paymentDeadline,
            )}
          >
            {tradingDocument.paymentStatus === "PAID" ? (
              <>
                {tradingDocumentConstants.invoicePaymentStatusDict[tradingDocument.paymentStatus]}
              </>
            ) : (
              "Nieopłacona"
            )}
          </StateLabel>
        </div>
        <div className="fw-700">
          {tradingDocument.paymentDeadlineDelta !== null &&
            tradingDocument.paymentStatus !== "PAID" && (
              <PaymentDeadlineDeltaLabel
                paymentDeadlineDelta={tradingDocument.paymentDeadlineDelta}
              />
            )}
        </div>
        <div className="fw-700">
          {tradingDocument.latestMessageStatus && tradingDocument.latestMessageStatus.length > 0 ? (
            <>
              {
                tradingDocumentConstants.tradingDocumentNotificationStatusDict[
                  tradingDocument.latestMessageStatus
                ]
              }
            </>
          ) : (
            "--"
          )}
        </div>
        <div>
          {tradingDocument.assignedTo && (
            <div className="d-flex align-items-center gap-1">
              {tradingDocument.assignedTo.avatar ? (
                <div className={cx(styles.listAvatar, styles.avatarSmall)}>
                  <img alt="avatar" src={tradingDocument.assignedTo.avatar} />
                </div>
              ) : (
                <InitialsAvatar
                  className={cx(styles.listAvatar, styles.avatarSmall)}
                  firstName={tradingDocument.assignedTo.firstName}
                  lastName={tradingDocument.assignedTo.lastName}
                  style={{ margin: 0 }}
                />
              )}
              <div className={styles.assignee}>
                {tradingDocument.assignedTo.firstName} {tradingDocument.assignedTo.lastName}
              </div>
            </div>
          )}
        </div>
        <div className={styles.subject}>
          {tradingDocument.sellerCompanyName ? (
            tradingDocument.sellerCompanyName
          ) : (
            <>
              {tradingDocument.sellerFirstName} {tradingDocument.sellerLastName}
            </>
          )}
        </div>
      </ModuleRow>
      {replyModal.isOpen && (
        <ReplyModal bulkInvoiceConfirmation={replyModal.state} close={replyModal.close} />
      )}
    </>
  );
};
