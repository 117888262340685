import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { customerApi } from "./api";
import { createApiQuery } from "hooks/createApiQuery";
import { useRedux, useSelector } from "hooks";
import { useMutation } from "hooks/useMutation";
import { assertIsDefined } from "utilities/assertIsDefined";
import produce from "immer";
import { customerKeys } from "./keys";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { getAnyErrorKey } from "utilities";

const useCustomerGusDetails = createApiQuery(customerApi.getCustomerGusDetails);
const useCustomers = createPaginatedApiQuery(customerApi.getCustomers);
const useCustomer = createApiQuery(customerApi.getCustomer);
const useCustomerEmployees = createPaginatedApiQuery(customerApi.getCustomerEmployees);

const usePatchCustomer = () => {
  const [dispatch, { partials }] = useRedux();
  const partialsState = useSelector(state => state.partials);

  return useMutation(customerApi.patchCustomer, ({ queryUtils }) => ({
    onMutate: ({ id, toUpdate }) => {
      const prevPanel = queryUtils.handleMutate(customerKeys.customerDetails(id), toUpdate);
      return { prevPanel };
    },
    onSuccess: payload => {
      const newPartials = produce(partialsState, draft => {
        const customerToUpdate = draft.customers.find(customer => customer.id === payload.id);
        assertIsDefined(customerToUpdate);
        Object.assign(customerToUpdate, payload);
      });
      dispatch(partials.setPartials(newPartials));
    },
    onError: (error, { id }, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      queryUtils.rollback(customerKeys.customerDetails(id), onMutateReturn.prevPanel, error);
    },
  }));
};

const useRemoveCustomerEmployee = () => {
  return withDeleteConfirmation(
    useMutation(customerApi.deleteCustomerEmployee, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries(customerKeys.customerEmployees());
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto użytkownika`,
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
    "Czy na pewno chcesz usunąć tego użytkownika?",
  )();
};

const usePostCustomerEmployee = (close: () => void) => {
  return useMutation(customerApi.postCustomerEmployee, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
      close();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

export const customerActions = {
  useCustomerEmployees,
  useCustomerGusDetails,
  useCustomers,
  useCustomer,
  usePatchCustomer,
  useRemoveCustomerEmployee,
  usePostCustomerEmployee,
};
