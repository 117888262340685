import { TradingDocument, TradingDocumentItem } from "api/trading-documents/models";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useItemsColumns } from "./useItemsColumns";
import { UUID } from "api/types";
import { EmptySection } from "components/common/emptySection/EmptySection";
import styles from "../../RightPanel.module.css";
import { cx } from "utilities";

interface Props {
  tradingDocument: TradingDocument;
}

export type NormalizedTradingDocumentItem = TradingDocumentItem & {
  itemId: UUID;
  order: {
    created: string;
    id: number | string;
    isDelivered: boolean;
    signature: string;
  } | null;
};

export const SalesInvoiceItems = ({ tradingDocument }: Props) => {
  const items = tradingDocument.items.flatMap(item =>
    item.tradingDocumentItems.map(tradingDocumentItem => ({
      ...tradingDocumentItem,
      itemId: item.id,
      order: item.order,
    })),
  );
  const columns = useItemsColumns(tradingDocument);
  const tableProps = useTableFeatureConnector({
    rows: items,
  });

  return (
    <Table<NormalizedTradingDocumentItem>
      columns={columns}
      error={null}
      isLoading={false}
      uiSchema={{
        header: {
          backgroundColor: "neutralWhite100",
        },
        cell: {
          height: "36",
        },
      }}
      {...tableProps}
      overrides={() => {
        return {
          ...tableProps.overrides?.(),
          row: row => {
            return {
              className: cx(
                {
                  [styles.deliveredTradingDocumentPosition]: row.order?.isDelivered,
                },
                tableProps.overrides?.().row?.(row).className,
              ),
            };
          },
          noResultComponent: <EmptySection className="mt-2" label="Brak pozycji na dokumencie" />,
        };
      }}
    />
  );
};
